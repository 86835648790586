import React, { FC } from 'react'

import VeneerModal from '@veneer/core/dist/scripts/modal'
import { IconInterface } from '@veneer/core/dist/scripts/icons/types'
import * as Styled from './styles'

interface StatusModalProps {
  Icon: React.ForwardRefExoticComponent<
    IconInterface &
      Omit<React.SVGAttributes<SVGSVGElement>, keyof IconInterface> &
      React.RefAttributes<SVGSVGElement>
  >
  closeModal: () => void
  onConfirmButton: () => void
  show: boolean
  label: string
  buttonText: string
  ['data-testid']?: string
}

export const StatusModal: FC<StatusModalProps> = ({
  closeModal,
  show,
  label,
  buttonText,
  onConfirmButton,
  Icon,
  ...props
}) => {
  return (
    <VeneerModal
      data-testid={props['data-testid']}
      show={show}
      closeButton={true}
      closeOnBlur={false}
      onClose={closeModal}
    >
      <Styled.ModalContainer>
        <Icon color="black" size={40} />
        <Styled.Label>{label}</Styled.Label>
        <Styled.ConfirmButton appearance="secondary" onClick={onConfirmButton}>
          {buttonText}
        </Styled.ConfirmButton>
      </Styled.ModalContainer>
    </VeneerModal>
  )
}
