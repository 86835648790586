import { useEffect } from 'react'
import { fetchBillingInfoAction, setErrorNotificationAction } from '../actions'
import { ErrorType } from '../types'
import { usePhcEventStatusPoller } from './usePhcEventStatus'
import { useDispatch } from './useDispatch'
import { useBillingFormState } from './useBillingFormState'

interface UseIFrameListenerProps {
  iFrameRef: React.RefObject<HTMLIFrameElement>
  setInitForm: React.Dispatch<React.SetStateAction<boolean>>
  analyticsEventRef: React.RefObject<HTMLButtonElement>
  onSave: (error?: ErrorType) => void
}

export const useIframeListenerEffect = ({
  iFrameRef,
  setInitForm,
  analyticsEventRef,
  onSave
}: UseIFrameListenerProps) => {
  const { enableAllPaymentLayout } = useBillingFormState()
  const phcEventStatusPoller = usePhcEventStatusPoller()
  const dispatch = useDispatch()

  useEffect(() => {
    const postMessageToIframe = (message: string, targetOrigin: string) => {
      const iFrameWindow = iFrameRef.current?.contentWindow
      iFrameWindow?.postMessage(
        message,
        targetOrigin.length > 0 ? targetOrigin : '*'
      )
    }

    const reloadIframe = async () => {
      try {
        await dispatch(fetchBillingInfoAction())
        setInitForm(true)
      } catch {
        // do nothing
      }
    }
    const iframeListener = async (event: MessageEvent) => {
      if (event.data.msg === 'handlePgsIframeSuccess') {
        dispatch(setErrorNotificationAction(false))
        try {
          const phcEventStatus = await phcEventStatusPoller({
            pickupId: event.data.pickupId
          })
          analyticsEventRef.current?.click()
          if (['success', 'successNoPaymentChanged'].includes(phcEventStatus)) {
            postMessageToIframe('displayPgsFormSuccess', event.origin)
            onSave()
            return
          }
        } catch {
          // do nothing
        }
        if (enableAllPaymentLayout) {
          await reloadIframe()
          dispatch(setErrorNotificationAction(true))
        } else {
          postMessageToIframe('displayPgsFormFailure', event.origin)
          setTimeout(reloadIframe, 5000)
        }
      }
    }
    window.addEventListener('message', iframeListener)
    return () => {
      window.removeEventListener('message', iframeListener)
    }
  }, [
    analyticsEventRef,
    dispatch,
    enableAllPaymentLayout,
    iFrameRef,
    onSave,
    phcEventStatusPoller,
    setInitForm
  ])
}
