import styled, { css } from 'styled-components'

export const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledStepNumber = styled.span`
  line-height: 20px;
  font-weight: bold;
`

export const StyledStepTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
`

export const StyledPgsForm = styled.iframe`
  width: 100%;
  height: 48px;
  border: none;
`

interface PgsProps {
  show: boolean
}

export const StyledPgsContainer = styled.div<PgsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { updatedLayout } }) =>
    updatedLayout &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.show
      ? css``
      : css`
          display: none;
        `}
`

export const StyledButtonSection = styled.div<{
  $separateStepOneButtons?: boolean
}>`
  margin-top: 16px;
  display: flex;
  width: 100%;

  ${(props) => {
    const mobile = css`
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 40px;

      button {
        margin-bottom: 16px;
      }
    `
    const tabDesk = css`
      margin-top: 30px;
      justify-content: ${props.$separateStepOneButtons
        ? 'space-between'
        : 'flex-end'};
      gap: 16px;
    `
    const responsive = {
      'mobile-container': mobile,
      'tablet-portrait-container': tabDesk,
      'tablet-landscape-container': tabDesk,
      'desktop-container': tabDesk
    }
    return responsive[props.theme.containerSize]
  }}
`
