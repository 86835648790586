import React from 'react'
import { BillingForm, BillingFormProps } from '../../src'
import { ThemeSettings } from '../../src/types'
import Root from '../root.component'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

export default function BillingFormMfe(props: {
  properties: BillingFormProps & { themeSettings: ThemeSettings }
}) {
  return (
    <Root>
      <VeneerThemeProvider
        customSemantics={props.properties.themeSettings.customSemantics}
        mode={props.properties.themeSettings.mode}
        shape={props.properties.themeSettings.shape}
        density={props.properties.themeSettings.density}
      >
        <BillingForm {...props.properties} />
      </VeneerThemeProvider>
    </Root>
  )
}
