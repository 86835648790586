import Button from '@veneer/core/dist/scripts/button'
import styled from 'styled-components'

export const Label = styled.p`
  font-size: 32px;
  line-height: 40px;
  margin: 24px 0;
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const ConfirmButton = styled(Button)`
  && {
    align-self: flex-end;
    margin-top: 16px;
  }
`
