import React, { useRef, useEffect, useState, FC } from 'react'
import {
  useOnSave,
  useOnSaveButtonClick,
  useBillingAddress,
  useBillingInfo,
  useLocale,
  useBillingFormState,
  useGetText,
  useIframeListenerEffect
} from '../../hooks'
import { Spinner } from '../Spinner'
import { StyledPgsContainer, StyledPgsForm } from '../Shared/styles'
import Button from '@veneer/core/dist/scripts/button'

interface PaymentProviderButtonProps {
  'data-testid': string
  provider: string
}

interface PhcRequestObjectType {
  EstGrandTotal?: number
  TotalTaxAmout?: number
  PurchaseCountry?: string
  ApplePay?: {
    PaymentDescription?: string
    IsSubscription?: boolean
    RecurringPaymentStartDate?: string
    RecurringPaymentDescription?: string
    RegularBilling?: {
      Label?: string
      Amount?: number
      PaymentTiming?: string
      StartDate?: string
    }
    TrialBilling?: {
      Label?: string
      Amount?: number
      PaymentTiming?: string
      EndDate?: string
    }
    IsServiceRequired?: boolean
    AuthShopperID?: string
  }
  GPay?: {
    IsServiceRequired?: boolean
    IsVK?: boolean
  }
  MerchantName?: string
  CustomerInfo: {
    IPAddress?: string
    FirstName?: string
    LastName?: string
    Locale?: string
    IsGuestUser?: boolean
  }
  BillAddress: {
    Address1?: string
    Address2?: string
    City?: string
    State?: string
    Zip?: string
    Country?: string
  }
  CustomField?: string
  OrderID?: string
  CustomerID?: string
  Culture?: string
}

export const PaymentProviderButton: FC<PaymentProviderButtonProps> = (
  props
) => {
  const { planPriceCents, totalFreeMonths, planPages } =
    useBillingFormState() || {}
  const {
    firstName,
    lastName,
    street1,
    street2,
    city,
    state,
    zipCode,
    countryCode
  } = useBillingAddress() || {}
  const {
    pgsIframeUrl,
    ipAddress,
    merchantName,
    orderId,
    customerId,
    customField
  } = useBillingInfo() || {}
  const getText = useGetText()
  const analyticsEventRef = useRef<HTMLButtonElement>(null)
  const onSave = useOnSave()
  const onSaveButtonClick = useOnSaveButtonClick()
  const locale = useLocale()
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [initForm, setInitForm] = useState(true)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const iFrameId =
    props.provider === 'apple' ? 'pgs-applepay-iframe' : 'pgs-gpay-iframe'
  const iFrameUrl = pgsIframeUrl?.replace(/\/$/, '')
  const currentDate = new Date()
  const trialEndDate = new Date(currentDate)
  trialEndDate.setMonth(currentDate.getMonth() + (totalFreeMonths || 0))
  trialEndDate.setDate(trialEndDate.getDate() - 1)
  const pages = `${planPages}`
  const recurringPaymentDescription = getText(
    'link_payment_page.apple_pay.recurring_payment_description',
    { pages }
  )

  useIframeListenerEffect({
    analyticsEventRef,
    iFrameRef,
    onSave,
    setInitForm
  })

  useEffect(() => {
    if (initForm && formRef.current) {
      formRef.current.submit()
      setInitForm(false)
    }
  }, [initForm, formRef])

  const renderForm = () => {
    if (initForm) {
      const zip = zipCode ? zipCode.toUpperCase() : zipCode
      const phcRequestObject: PhcRequestObjectType = {
        EstGrandTotal: 0,
        MerchantName: merchantName,
        CustomerInfo: {
          FirstName: firstName,
          LastName: lastName
        },
        BillAddress: {
          Address1: street1,
          Address2: street2,
          City: city,
          State: state,
          Zip: zip,
          Country: countryCode
        },
        OrderID: orderId,
        CustomerID: customerId,
        Culture: locale.replace('_', '-')
      }

      if (props.provider === 'apple') {
        phcRequestObject.ApplePay = {
          PaymentDescription: 'HP Instant Ink Payment',
          IsSubscription: true,
          RecurringPaymentStartDate: currentDate.toISOString(),
          RecurringPaymentDescription: recurringPaymentDescription,
          RegularBilling: {
            Label: 'HP Instant Ink',
            Amount: (planPriceCents || 0) / 100.0,
            PaymentTiming: 'recurring',
            StartDate: trialEndDate.toISOString()
          },
          TrialBilling: {
            Label: 'HP Instant Ink Trial',
            Amount: 0,
            PaymentTiming: 'recurring',
            EndDate: trialEndDate.toISOString()
          },
          IsServiceRequired: true,
          AuthShopperID: 'testShopperID'
        }
        phcRequestObject.TotalTaxAmout = 0
        phcRequestObject.PurchaseCountry = countryCode
        phcRequestObject.CustomerInfo.Locale = locale.replace('_', '-')
        phcRequestObject.CustomerInfo.IsGuestUser = true
      } else {
        phcRequestObject.GPay = {
          IsServiceRequired: true,
          IsVK: false
        }
        phcRequestObject.CustomField = JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          Address1: street1,
          Address2: street2,
          City: city,
          State: state,
          Zip: zip,
          CountryCode: countryCode,
          submitNoDataTouched: true,
          ...(customField ? JSON.parse(customField) : {})
        })
        phcRequestObject.CustomerInfo.IPAddress = ipAddress
      }
      const phcRequest = JSON.stringify(phcRequestObject)
      return (
        <form
          ref={formRef}
          method="post"
          id="postToIframe"
          action={iFrameUrl}
          target={iFrameId}
        >
          <input type="hidden" name="phcRequest" value={phcRequest} />
        </form>
      )
    }
    return null
  }

  return (
    <div data-testid={props['data-testid']}>
      {iframeLoaded ? null : <Spinner />}
      <StyledPgsContainer show={iframeLoaded}>
        <StyledPgsForm
          id={iFrameId}
          title={iFrameId}
          key={iFrameId}
          ref={iFrameRef}
          name={iFrameId}
          allow={`payment ${iFrameUrl}`}
          onLoad={() => setIframeLoaded(true)}
        />
        {renderForm()}
      </StyledPgsContainer>
      <Button
        data-analyticsid="SaveButton"
        style={{ display: 'none' }}
        ref={analyticsEventRef}
        onClick={(event) => {
          event.persist()
          onSaveButtonClick(event)
        }}
      />
    </div>
  )
}
