import React, { FC, useState } from 'react'
import { LinkPaymentProviderButton } from './linkPaymentProviderButton'
import { ApplePayIcon } from '../ApplePay'
import { GPayIcon } from '../GPay'
import paypalIcon from '../PayPal/pay-pal-horizontal.png'
import IconChecmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'
import IconLink from '@veneer/core/dist/scripts/icons/icon_link'

import * as Styled from './styles'
import { useBillingFormState } from '../../hooks'
import { StatusModal } from '../StatusModal'

export const LinkPaymentProvider: FC<{
  ['data-testid']: string
}> = (props) => {
  const { enablePaypalInNativeApp, enableGPayInNativeApp } =
    useBillingFormState()

  const enableApplePayInNativeApp = false // WIP

  const [showModal, setShowModal] = useState(false)
  const [modalState, setModalState] = useState(0)

  const openModal = () => {
    setShowModal(true)
    setModalState(1)
  }

  const closeModal = () => {
    setShowModal(false)
    setModalState(0)
  }

  const onDone = () => {
    closeModal()
    // WIP
  }

  const onCheckStatus = () => {
    setModalState(2)
    // WIP
  }

  const CheckStatusModal = () => (
    <StatusModal
      Icon={IconLink}
      label="Check to see if your account linked successfully."
      buttonText="Check Status"
      closeModal={closeModal}
      onConfirmButton={onCheckStatus}
      show={showModal}
      data-testid="check-status-modal"
    />
  )

  const GpaySuccessModal = () => (
    <StatusModal
      Icon={IconChecmarkCircle}
      label="Google Pay was linked successfully. "
      buttonText="Done"
      closeModal={closeModal}
      onConfirmButton={onDone}
      show={showModal}
      data-testid="gpay-success-modal"
    />
  )

  const onSaveButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.persist()
    openModal()
    window.open('https://google.com', '_blank', 'noopener') // WIP
  }

  return (
    <div data-testid={props['data-testid']}>
      {modalState == 1 && <CheckStatusModal />}
      {modalState == 2 && <GpaySuccessModal />}
      <LinkPaymentProviderButton onClick={onSaveButton} />
      <Styled.IconsContainer>
        {enableApplePayInNativeApp && (
          <ApplePayIcon data-testid="applepay-icon" />
        )}
        {enableGPayInNativeApp && <GPayIcon data-testid="gpay-icon" />}
        {enablePaypalInNativeApp && (
          <img
            data-testid="paypal-icon"
            height={24}
            src={paypalIcon}
            alt="paypal-icon"
          />
        )}
      </Styled.IconsContainer>
    </div>
  )
}
