import React, { FC } from 'react'
import { PaymentProviderButton } from '../PaymentProviderButton'

export const GPayButton: FC<{ ['data-testid']: string }> = (props) => {
  return (
    <PaymentProviderButton
      key="google-payment-provider-button"
      data-testid={props['data-testid']}
      provider="google"
    />
  )
}
