import React, { FC } from 'react'
import { PaymentProviderButton } from '../PaymentProviderButton'

export const ApplePayButton: FC<{ ['data-testid']: string }> = (props) => {
  return (
    <PaymentProviderButton
      key="apple-payment-provider-button"
      data-testid={props['data-testid']}
      provider="apple"
    />
  )
}
