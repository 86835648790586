import { useEffect, useState } from 'react'

declare global {
  interface Window {
    ApplePaySession: any
  }
}

export const useApplePay = () => {
  const [applePayAvailable, setApplePayAvailable] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js'
    script.onload = () => {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        setApplePayAvailable(true)
      }
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return applePayAvailable
}
