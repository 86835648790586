import React from 'react'
import projectNames from '../src/configs/projectNames'

export default function Root({ children }: { children: React.ReactNode }) {
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      {children}
    </section>
  )
}
