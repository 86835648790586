import React, { FC } from 'react'
import * as Styled from './styles'
import IconExternalLink from '@veneer/core/dist/scripts/icons/icon_external_link'

interface LinkPaymentProviderButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const LinkPaymentProviderButton: FC<LinkPaymentProviderButtonProps> = ({
  onClick
}) => {
  return (
    <div data-testid="linkpaymentprovider-button">
      <Styled.LinkPaymentProviderButton
        className={'instant-ink-enroll-hpx-button-override'}
        data-testid="save-button"
        data-analyticsid="SaveButton"
        disabled={false}
        onClick={onClick}
      >
        Link a payment provider
        <IconExternalLink
          data-testid="link-payment-provider-icon"
          color="white"
        />
      </Styled.LinkPaymentProviderButton>
    </div>
  )
}
