import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'

export const LinkPaymentProviderButton = styled(Button)`
  && {
    width: 100%;

    > span > svg {
      margin-left: 8px;
    }
  }
`

export const IconsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  > svg {
    margin-right: 16px;
  }
`
